<template>
  <div
    id="labor-recruitment-id"
  >
    <div class="scroll-table table-model">
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- SỐ LƯỢNG (NGƯỜI)-->
          <span v-if="props.column.field === 'data'">
            <b-form-input
              :id="`focus-input-${props.row.titleReportId}`"
              :value="props.row.data"
              @focus="focusTextChange(props.row.titleReportId)"
              @change="handleCountData($event, props.row.titleReportId, props.row.note)"
            />
          </span>

          <!-- GHI CHÚ-->
          <span v-else-if="props.column.field === 'note'">
            <span v-if="props.row.isNotReportDepartment">
              <b-form-input
                v-if="props.row.isEdit"
                :id="`focus-inputs-${props.row.titleReportId}`"
                v-b-tooltip.hover.top="'% tăng, giảm so với cùng kỳ Quý II năm trước'"
                :value="props.row.note"
                @focus="focusChangeValue(props.row.titleReportId)"
                @change="handleCountNote($event, props.row.titleReportId, props.row.data)"
              />
              <span v-else>{{ props.row.note }}</span>
            </span>
          </span>

        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    reportMonthAccidentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dataList: [],
      columns: [
        {
          label: 'STT',
          field: 'stringOrder',
          sortable: false,
        },
        {
          label: 'CÁC HOẠT ĐỘNG',
          field: 'name',
          sortable: false,
        },
        {
          label: 'ĐƠN VỊ',
          field: 'unit',
          sortable: false,
        },
        {
          label: 'SỐ LƯỢNG',
          field: 'data',
          sortable: false,
        },
        {
          label: 'GHI CHÚ',
          field: 'note',
          sortable: false,
        },
      ],
      data: 0,
      note: '',
      changeColor: true,
    }
  },
  created() {
    this.fetchData(this.reportMonthAccidentId)
  },
  methods: {
    // Fetch data
    async fetchData(reportMonthAccidentId) {
      const param = {
        reportMonthAccidentId,
      }
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_REPORT_MONTH_ACCIDENT_TITLES, {
        params: param,
      })
      this.dataList = data
      this.$hideLoading()
    },

    // Gọi API khi điền vào input
    async handleCountData(value, id, note) {
      const typeVal = value !== null && value !== '' ? Number(value) : null
      this.data = typeVal
      if (!typeVal === true && typeVal !== 0) {
        this.data = 0
        const el = document.getElementById(`focus-input-${id}`)
        el.style.border = '1px solid red'
        this.$root.$bvToast.toast('Bắt buộc điền số vào ô trống', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        const el = document.getElementById(`focus-input-${id}`)
        el.style.border = '1px solid #A6A8B0'
        const model = {
          reportMonthAccidentId: this.reportMonthAccidentId,
          titleReportId: id,
          data: this.data,
          note,
        }
        await axiosApiInstance.post(ConstantsApi.CREATE_REPORT_MONTH_ACCIDENT_TITLES, model)
      }
    },

    // Gọi API khi điền vào input
    async handleCountNote(value, id, data) {
      this.note = value
      const model = {
        reportMonthAccidentId: this.reportMonthAccidentId,
        titleReportId: id,
        note: this.note,
        data: Number(data),
      }
      await axiosApiInstance.post(ConstantsApi.CREATE_REPORT_MONTH_ACCIDENT_TITLES, model)
    },

    // Bôi đen giá trị sau khi click vào ô input
    focusTextChange(index) {
      const el = document.getElementById(`focus-input-${index}`)
      el.focus()
      el.setSelectionRange(0, el.value.length)
    },

    // Bôi đen giá trị sau khi click vào ô input
    focusChangeValue(index) {
      const el = document.getElementById(`focus-inputs-${index}`)
      el.focus()
      el.setSelectionRange(0, el.value.length)
    },
  },
}
</script>
